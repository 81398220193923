import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  // {
  //   path: '',
  //   redirectTo: 'formular',
  //   pathMatch: 'full'
  // },
  {
    path: '',
    loadChildren: () =>
      import('./modules/configurator/configurator.module').then(
        (m) => m.ConfiguratorModule
      )
  },
  {
    path: 'error',
    loadChildren: () =>
      import('./modules/error-page/error-page.module').then(
        (m) => m.ErrorPageModule
      )
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
