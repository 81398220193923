<div class="component-container py-5">
  <div class="container">
    <div class="row gap-4">
      <div class="col-12 col-lg-4">
        <p class="header-3 pb-2">JANA Jagd + Natur</p>

        <p class="medium">
          Ihr Marken-Onlineshop für Jagd und Natur. Wir rüsten Jäger, Jungjäger oder Ihren Hund komplett für die Jagd aus! Von Bekleidung
          bis
          Ausrüstung ist für jeden etwas dabei. Hier kauft der Jäger!
        </p>
      </div>

      <div class="col-12 col-md-6 offset-xl-1 col-lg">
        <div class="d-flex align-items-center">
          <img class="icon" src="/assets/icons/phone_circle.svg" alt="phone icon">
          <div class="ps-3 d-flex flex-column">
            <p class="medium">Telefonische Bestellung unter:</p>
            <p>05661 - 92 62 0</p>
          </div>
        </div>

        <div class="mt-4 d-flex align-items-center">
          <img class="icon" src="/assets/icons/mail_circle.svg" alt="mail icon">
          <div class="ps-3 d-flex flex-column">
            <p class="medium">Email an:</p>
            <p>info@jana-jagd.de</p>
          </div>
        </div>
      </div>

      <div class="col-12 col-md">
        <p class="header-3 pb-2">Informationen</p>

        <div class="d-flex flex-column gap-1">
          <a class="medium" href="#">Impressum</a>
          <a class="medium" href="#">Datenschutz</a>
          <a class="medium" href="#">Über Jana</a>
        </div>
      </div>
    </div>
  </div>
</div>
