// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment: Environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyBdMn7uW7xwniyH_N3To4ZjrjeofrQCTu8',
    authDomain: 'jana-jagd-jaegerbrief.firebaseapp.com',
    projectId: 'jana-jagd-jaegerbrief',
    storageBucket: 'jana-jagd-jaegerbrief.appspot.com',
    messagingSenderId: '835012191492',
    appId: '1:835012191492:web:42bd2b36c06515ce4f1c51'
  },
  emails: {
    emailServiceBaseUrl: 'https://prod-dot-exportarts-mail-api.ey.r.appspot.com',
    accountUuid: 'f37ba615-fe03-4cd7-b422-c8dca403c49b',
    templateUuid: 'b7f28368-c30d-4295-bf73-f773c40c70dd',
    internalEmail: 'tim@exportarts.io',
    baseUrl: 'https://jana-configurator.exportarts.io/generate'
  }
};

export interface Environment {
  production: boolean;
  firebase: {
    apiKey: string;
    authDomain: string;
    projectId: string;
    storageBucket: string;
    messagingSenderId: string;
    appId: string;
  };
  emails: {
    emailServiceBaseUrl: string;
    accountUuid: string;
    templateUuid: string;
    internalEmail: string;
    baseUrl: string;
  };
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
